export const generateChips = (item) => {
	const { listing_type, title } = item;
	const words = title.toLowerCase().split(' ');
	const chips = [];
	if (words.includes('private') && listing_type === 'PRIVATE_TRANSFER') {
		chips.push('Private Transfer');
		chips.push('Minivan');
	}
	if (words.includes('shared') && listing_type === 'SHARED_TRANSFER') {
		chips.push('Shared Transfer');
		chips.push('Minivan');
		chips.push('Budget-friendly');
		chips.push("Backpacker's Choice");
	}
	if (words.includes('underground')) {
		chips.push('Private Transfer');
		chips.push('Guided Tour');
		chips.push('Underground River Tour');
	}
	return chips;
};
