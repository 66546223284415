<template>
	<v-lazy :min-height="200" :options="{ threshold: 0.5 }" transition="fade-transition">
		<v-card :to="generateHref(item)" class="blog-card overflow-hidden hover-card center-card">
			<NuxtImg v-show="!hideChips" loading="lazy" format="webp" quality="75" class="elevation-1" :alt="item.title" :src="item.thumbnail" height="280" width="100%" fit="cover" />
			<NuxtImg v-show="hideChips" loading="lazy" format="webp" quality="75" class="elevation-1" :alt="item.title" :src="item.thumbnail" height="230" width="100%" fit="cover" />
			<div class="date-badge text-small bg-danger-grediant">
				<span>{{ formatCurrency(baseRate, currency, locale) }}</span>
			</div>

			<h4 class="font-weight-medium font-16 ma-2">
				{{ item.title }}
			</h4>
			<div class="badge-operator bg-info-grediant" v-if="!hideChips">
				<span
					>Operated by <strong>{{ item.serviceProvider.name }}</strong></span
				>
			</div>
			<template v-if="!hideChips">
				<ClientOnly>
					<div class="text-start">
						<v-chip prepend-icon="mdi-star" class="ma-1" color="orange" density="compact"> {{ parseFloat(Math.random() + 4).toFixed(1) }} ( {{ item.reviewCount }}) </v-chip>
						<v-chip color="blue" density="compact" class="ma-1" v-if="item.isBestSeller"> Bestseller </v-chip>
						<v-chip prepend-icon="mdi-thumb-up-outline" color="green" density="compact" class="ma-1" v-if="item.serviceProvider.name === 'Diskubre Palawan'"> Recommended by Travellers </v-chip>
						<v-chip :color="getColor()" density="compact" class="ma-1" v-for="chip in generateChips(item)" :key="chip">
							{{ chip }}
						</v-chip>
					</div>
				</ClientOnly>
			</template>
		</v-card>
	</v-lazy>
</template>
<script setup>
import { useBookingStore } from '~/stores/bookingStore';
import { storeToRefs } from 'pinia';
const store = useBookingStore();
const { currency, locale } = storeToRefs(store);

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	pax: {
		type: Number,
		required: false,
		default: null,
	},
	hideChips: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const baseRate = ref(props.item.ratePlans[currency.value].base || props.item.ratePlan.base || 0);

watch(currency, () => {
	baseRate.value = props.item.ratePlans[currency.value].base || props.item.ratePlan.base || 0;
});
const generateHref = (item) => {
	const transfersType = ['PRIVATE_TRANSFER', 'SHARED_TRANSFER', 'PRIVATE_TRANSFER_WITH_TOUR'];
	return transfersType.includes(item.listing_type) ? `/transfers/${item.slug}` : `/tours/${item.slug}`;
};
const colors = ['indigo', 'success', 'warning', 'error', 'info', 'teal', 'primary', 'green', 'orange', 'blue'];
const getColor = () => colors[Math.floor(Math.random() * colors.length)];
</script>
<style scoped>
.title {
	word-break: break-all;
	white-space: break-spaces;
	font-size: 1rem;
	line-height: 1rem;
}

.badge-left {
	left: 10px !important;
}

.date-badge {
	padding: 5px !important;
}

.date-badge span {
	font-size: 12px !important;
}

.badge-operator span {
	font-size: 12px !important;
	color: white;
}

.badge-operator {
	padding: 5px;
}

.hover-card:hover {
	transform: scale(1.05);
}

img {
	object-fit: cover;
	width: 100%;
}
</style>
